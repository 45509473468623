import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Alert } from '@mui/material';
import { AuthContext } from '../../context/Auth/AuthContext'; // Certifique-se de que o caminho está correto

const BlazeForm = () => {
  const companyId = localStorage.getItem("companyId");
  const [bucketId, setBucketId] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [message, setMessage] = useState('');

  const { user } = useContext(AuthContext);

  const isSuper = () => {
    return user.super;
  };

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/blaze/${companyId}`);
        if (response.data) {
          setBucketId(response.data.bucketId);
          setBaseUrl(response.data.baseUrl);
        }
      } catch (error) {
        console.error('Error fetching Blaze data:', error);
      }
    };

    fetchData();
  }, [companyId, backendUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${backendUrl}/blaze`, {
        companyId,
        bucketId,
        baseUrl,
      });

      setMessage('Blaze data created successfully!');
    } catch (error) {
      setMessage('Error creating Blaze data. Please try again.');
    }
  };

  if (!isSuper()) {
    return null; 
  }

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Integração BackBlaze B2 - S3
      </Typography>
      <Typography variant="body1" paragraph>
        Esta integração permite que você conecte seu sistema ao serviço de armazenamento BackBlaze B2,
        utilizando a API S3 compatível. Com isso, você pode gerenciar e armazenar seus dados na nuvem de maneira
        eficiente e segura.
      </Typography>
      <Typography variant="body1" paragraph>
        Preencha os campos abaixo com o ID do bucket e a URL base fornecidos pelo BackBlaze B2. 
        Certifique-se de que as informações estejam corretas para garantir uma integração bem-sucedida.
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Bucket ID"
          fullWidth
          value={bucketId}
          onChange={(e) => setBucketId(e.target.value)}
          required
          margin="normal"
        />
        <TextField
          label="Base URL"
          fullWidth
          value={baseUrl}
          onChange={(e) => setBaseUrl(e.target.value)}
          required
          margin="normal"
        />
        
        <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }}>
          Enviar
        </Button>
      </form>
      
      {message && (
        <Alert severity={message.includes('successfully') ? 'success' : 'error'} style={{ marginTop: '1rem' }}>
          {message}
        </Alert>
      )}
    </Container>
  );
};

export default BlazeForm;

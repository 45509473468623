import React, { useState } from 'react';
import { Select, FormControl, InputLabel, MenuItem, Typography, Grid, Snackbar, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import moment from 'moment-timezone';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.dark,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.dark,
    },
  },
  mapContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}));

const brTimeZones = [
  'America/Sao_Paulo',
  'America/Bahia',
  'America/Araguaina',
  'America/Belem',
  'America/Boa_Vista',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Eirunepe',
  'America/Fortaleza',
  'America/Maceio',
  'America/Manaus',
  'America/Noronha',
  'America/Porto_Velho',
  'America/Recife',
  'America/Rio_Branco',
  'America/Santarem',
];

const countryTimeZones = {
  BRA: 'America/Sao_Paulo',
  COL: 'America/Bogota',
  VEN: 'America/Caracas',
  GUF: 'America/Cayenne',
  SUR: 'America/Paramaribo',
  ARG: 'America/Argentina/Buenos_Aires',
  CHL: 'America/Santiago',
  USA: 'America/New_York',
  CAN: 'America/Toronto',
  MEX: 'America/Mexico_City',
  CUB: 'America/Havana',
  SLV: 'America/El_Salvador',
  ESP: 'Europe/Madrid',
  NER: 'Africa/Niamey',
  BWA: 'Africa/Gaborone',
  COD: 'Africa/Kinshasa',
  ZAF: 'Africa/Johannesburg',
  TZA: 'Africa/Dar_es_Salaam',
  // Adicione outros mapeamentos de países para fusos horários aqui
};

const geoUrl = 'https://raw.githubusercontent.com/innovationstudios/apiyaml/main/t.json';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TimeZoneDropdown({ value, setFieldValue }) {
  const classes = useStyles();
  const [selectedTimezone, setSelectedTimezone] = useState(value || brTimeZones[0]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSelect = (e) => {
    const timezone = e.target.value;
    setSelectedTimezone(timezone);
    if (setFieldValue) {
      setFieldValue('timezone', timezone);
    }
    setOpenSnackbar(true); // Mostrar mensagem de sucesso
  };

  const handleMapClick = (geo) => {
    const countryCode = geo.properties.ISO_A2 || geo.properties.ISO_A3 || geo.id;
    if (!countryCode) {
      console.log('No country code found for the selected region');
      return;
    }
    const countryTimeZone = countryTimeZones[countryCode];
    if (countryTimeZone) {
      setSelectedTimezone(countryTimeZone);
      if (setFieldValue) {
        setFieldValue('timezone', countryTimeZone);
      }
      setOpenSnackbar(true); // Mostrar mensagem de sucesso
    } else {
      console.log(`No timezone mapping found for country code: ${countryCode}`);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <FormControl fullWidth variant="outlined" margin="dense" className={classes.formControl}>
        <InputLabel shrink>Fuso Horário</InputLabel>
        <Select
          labelId="timezone-select-label"
          id="timezone-select"
          label="Fuso Horário"
          value={selectedTimezone}
          onChange={handleSelect}
          className={classes.select}
        >
          {brTimeZones.map((timezone, index) => (
            <MenuItem key={index} value={timezone}>
              {timezone}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container className={classes.mapContainer}>
        <Grid item xs={12}>
          <ComposableMap>
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onClick={() => handleMapClick(geo)}
                    style={{
                      default: {
                        fill: '#D6D6DA',
                        outline: 'none',
                      },
                      hover: {
                        fill: '#F53',
                        outline: 'none',
                      },
                      pressed: {
                        fill: '#E42',
                        outline: 'none',
                      },
                    }}
                  />
                ))
              }
            </Geographies>
          </ComposableMap>
        </Grid>
      </Grid>
      <Typography variant="h6" align="center" style={{ marginTop: '16px' }}>
        Fuso Horário Atual: {selectedTimezone}
      </Typography>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Fuso horário atualizado com sucesso!"
      >
        <Alert onClose={handleCloseSnackbar} severity="success" className={classes.alert}>
          Fuso horário atualizado com sucesso!
        </Alert>
      </Snackbar>
    </div>
  ); 
}

export default TimeZoneDropdown;

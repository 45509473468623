import axios from 'axios';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const validateLicense = async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/validade`);
    return response.data; // A resposta deve conter o campo `plano`
  } catch (error) {
    console.error('Erro ao validar a licença:', error);
    throw error;
  }
};

import React, { useEffect, useState, useContext } from "react";
import { Grid } from '@material-ui/core';
import MainHeader from '../../components/MainHeader';
import Title from '../../components/Title';
import MainContainer from '../Reports/components/MainContainer';
import IntegrationLinkBox from './components/IntegrationLinkBox';
import flowiseImg from '../../assets/FlowiseAI-Logo.png';
import backblazeImg from '../../assets/backblaze.png'; // Certifique-se de que a imagem esteja no caminho correto
import { AuthContext } from "../../context/Auth/AuthContext";

const Integrations = () => {
  const { user } = useContext(AuthContext);

  const isSuper = () => {
    return user.super;
  };

  return (
    <MainContainer>
      <MainHeader>
        <Title>Integrações</Title>
      </MainHeader>
      <Grid container spacing={4} sx={{ overflowY: 'unset' }}>
        {/* FLOWISE */}
        <Grid item xs={3}>
          <IntegrationLinkBox
            title="FlowIse"
            link="/integrations/flowise"
            customStyle={{ marginTop: '55px' }}
            img={flowiseImg}
          />
        </Grid>
        {/* BackBlaze B2 */}
        {isSuper() ?
        <Grid item xs={3}>
          <IntegrationLinkBox
            title="BackBlaze B2"
            link="/integrations/backblaze"
            customStyle={{ marginTop: '55px' }}
            img={backblazeImg}
          />
        </Grid>
        : null}
      </Grid>
    </MainContainer>
  );
};

export default Integrations;
